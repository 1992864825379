@import 'styles/colors.scss';

hr {
    border-width: 1px;
}

.navigation-links{
    color: $primary;
}

.is-active, 
.is-active:hover {
    color:$aa-gray;
    text-decoration: none;
    pointer-events: none;
    display: inline-block;
    cursor: default;
}
