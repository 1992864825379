@import 'styles/colors.scss';

nav button#menu-button {
    float: right;
    font-weight: bold;
    border-radius: 8px;
    border: 2px solid;
    &.menu-button-blue {
        background-color: $aa-blue;
        border-color: white;
        color: white;
        padding: 3px 10px !important;
        height: auto;
    }
    &.menu-button-white {
        background-color: white;
        border-color: $aa-blue;
        color: $aa-blue;
        padding: 3px 10px !important;
        height: auto;
    }
}

.navbar{
    &.navbar-mobile{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    min-height: 40px !important;
    max-height: 40px !important;
 }
    &.navbar-desktop {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    min-height: 50px !important; 
 }
}

.button{
    &.button-text {
        font-size: 0.5rem;
    }
}
