$aa-black:            #131313;
$aa-blue:             #0078d2;
$aa-blue-dark:        #00467f;
$aa-blue-light:       #4db4fa;
$aa-gray:             #9da6ab;
$aa-gray-dark:        #36495a;
$aa-gray-light:       #d0dae0;
$aa-gray-ultra-light: #ebeef0;
$aa-green:            #00b989;
$aa-orange:           #ff7318;
$aa-orange-light:     #faaf00;
$aa-red:              #c30019;
$aa-yellow-green:     #d1d532;

$primary:   $aa-blue;
$secondary: $aa-gray;
$success:   $aa-green;
$danger:    $aa-red;
$warning:   $aa-orange-light;
$info:      $aa-blue-light;
$dark:      $aa-gray-dark;
$light:     $aa-gray-ultra-light;
$theme-colors: ();