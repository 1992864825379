@import 'styles/colors.scss';

.App {
    background-color: $aa-gray-ultra-light;

    .app-body {
        background-color: white;
        min-height: 100vh;

        .scss-test {
            color: $aa-green;
        }

        .cursor-pointer {
            cursor: pointer;
        }
        
        span.no-focus-outline:focus {
            outline: none;
        }

        .border-2px {
            border: solid 2px;
        }

        .font-size-16 {
            font-size: 16px;
        }

        .font-size-22 {
            font-size: 22px;
        }

        thead, tbody {
            color: $aa-gray-dark;
        }
    }
}

.hidden {
    display: none;
}

.max-width {
    max-width: 1800px;
}

.cursor-pointer {
    cursor: pointer;
}

.hover-underline:hover {
    text-decoration: underline;
}
