@import 'styles/colors.scss';

.app-footer {
    nav.navbar {
        background-color: $aa-gray;
        font-size: 16px;
        color: $aa-black;
        padding: 2px 10px;

        .footer-text {
            font-size: 14px;
        }
    }
}
