@font-face {
    font-family: 'AmericanSans';
    src: url(./assets/fonts/americansans-regular.eot) format('embedded-opentype'),
         url(./assets/fonts/americansans-regular.svg) format('svg'),
         url(./assets/fonts/americansans-regular.ttf) format('truetype'),
         url(./assets/fonts/americansans-regular.woff) format('woff');
}

@font-face {
    font-family: 'AmericanIcons';
    font-style: normal;
    src: url(./assets/icons/american-icons-v5-0.otf) format('opentype'),
         url(./assets/icons/american-icons-v5-0.woff) format('woff');
}

body {
  margin: 0;
  font-family: AmericanSans, AmericanIcons, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}