@import 'styles/colors.scss';

.loader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(white, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 1;
}

.loader-content {
    text-align: center;
}

.loader-img {
    position: relative;
    overflow: hidden;
    width: 120px;
    height: 120px;
}