.icon-calendar:before {
    content: '\10e03a';
}
.icon-cancel:before {
    content: '\10e04a';
}
.icon-cancel-button:before {
    content: '\10e04c';
}
.icon-cash:before {
    content: '\10e022';
}
.icon-check:before {
    content: '\10e049';
}
.icon-check-button:before {
    content: '\10e04b';
}
.icon-collapse:before {
    content: '\10e04e';
}
.icon-delete:before {
    content: '\10e088';
}
.icon-down:before {
    content: '\10e041';
}
.icon-down-button:before {
    content: '\10e045';
}
.icon-email:before {
    content: '\10e02d';
}
.icon-error:before {
    content: '\10e03d';
}
.icon-expand:before {
    content: '\10e04f';
}
.icon-feedback:before {
    content: '\10e035';
}
.icon-gear:before {
    content: '\10e036';
}
.icon-home:before {
    content: '\10e033';
}
.icon-information:before {
    content: '\10e03c';
}
.icon-left:before {
    content: '\10e042';
}
.icon-left-button:before {
    content: '\10e047';
}
.icon-list:before {
    content: '\10e06c';
}
.icon-menu:before {
    content: '\10e06d';
}
.icon-message:before {
    content: '\10E034';
}
.icon-minus-button:before {
    content: '\10e070';
}
.icon-mobile:before {
    content: '\10e027';
}
.icon-more:before {
    content: '\10e04d';
}
.icon-navigate:before {
    content: '\10e032';
}
.icon-new-web-page:before {
    content: '\10e03f';
}
.icon-no:before {
    content: '\10e904';
}
.icon-notification:before {
    content: '\10e034';
}
.icon-page:before {
    content: '\10e074';
}
.icon-pencil:before {
    content: '\10e06b';
}
.icon-phone:before {
    content: '\10e02a';
}
.icon-plus-button:before {
    content: '\10e071';
}
.icon-print:before {
    content: '\10e02b';
}
.icon-question:before {
    content: '\10e03b';
}
.icon-right:before {
    content: '\10e043';
}
.icon-right-button:before {
    content: '\10e048';
}
.icon-search:before {
    content: '\10e039';
}
.icon-unlock:before {
    content: '\10e038';
}
.icon-up:before {
    content: '\10e040';
}
.icon-up-button:before {
    content: '\10e044';
}
.icon-warning:before {
    content: '\10e03e';
}
.icon-website:before {
    content: '\10e02c';
}