@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "colors.scss";

$body-color: $aa-gray-dark;

$font-family-base: AmericanSans, AmericanIcons, Arial, Helvetica, sans-serif;
$font-size-base: 0.875rem;

//h1 and h3 sizing come from sti calculator. All other sizings are unofficial.
$h1-font-size: 30px;
$h2-font-size: 24px;
$h3-font-size: 18px;
$h4-font-size: 16px;
$h5-font-size: 16px;
$h6-font-size: 16px;

$headings-color: $primary;

$hr-border-color: $aa-gray-ultra-light;
$hr-border-width: 1px;
$hr-margin-y: 2px;

body h1 {
    margin-top: 30px;
    margin-bottom: 30px;
}

body .navbar, body .navbar-brand, .navbar-expand { 
    font-size: 30px; 
}

.text-aa-blue-dark {
    color: $aa-blue-dark;
}

.text-aa-black {
    color: $aa-black;
}

@import "../../node_modules/bootstrap";